import React from 'react';

const ZoomResetIcon = () => {
  return (
    <svg viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5081 28.6313L27.2179 27.9219C27.4132 27.7267 27.4133 27.4102 27.218 27.2149L25.7988 25.7957C25.6035 25.6004 25.2869 25.6004 25.0916 25.7956L24.3817 26.505C20.8626 30.022 15.1397 30.0211 11.6217 26.5031C8.10364 22.9851 8.10455 17.2639 11.6237 13.747C15.1428 10.23 20.8657 10.2308 24.3837 13.7488L25.4469 14.812L23.1238 17.1337C22.83 17.4273 23.0016 17.9302 23.4135 17.9831L31.1899 18.9832C31.513 19.0247 31.7889 18.7514 31.7503 18.4281L30.8155 10.5906C30.7661 10.1763 30.2606 10.0012 29.9654 10.2963L27.5739 12.6863L26.5108 11.6231C21.8211 6.93349 14.1885 6.93236 9.49736 11.6206C4.80623 16.3089 4.80502 23.9392 9.49465 28.6288C14.1843 33.3184 21.8169 33.3196 26.5081 28.6313Z"
        fill="#F0F0F0"
      />
    </svg>
  );
};
export default ZoomResetIcon;
