import { graphql, navigate, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { memo } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  backBtn,
  closeOverlay,
  closeText,
  creditsContainer,
  header,
  logosContainer,
  logoWrapper,
  text1Container,
  text2Container,
  touchwallGrid,
} from './Credits.module.scss';

const Credits = () => {
  const { strapiCredits } = useStaticQuery(
    graphql`
      query {
        strapiCredits {
          logos {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80)
              }
            }
          }
          text_col_1
          text_col_2
        }
      }
    `
  );
  const { logos, text_col_1, text_col_2 } = strapiCredits;

  const touchwall = process.env.GATSBY_CAMDEN_OFFLINE === 'true';
  return (
    <div
      id="credits"
      className={`${creditsContainer} creditsPage ${
        touchwall ? touchwallGrid : ''
      }`}
    >
      {/* The Back button is only visible in the web version. See below for how you close the Credits. */}
      {!touchwall && (
        <div className={`${header}`}>
          <button
            className={`${backBtn} buttonTextFooter`}
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
      )}
      <div className={`${logosContainer}`}>
        {logos.map(({ localFile }, index) => (
          <div className={`${logoWrapper}`} key={index}>
            <GatsbyImage image={getImage(localFile.childImageSharp)} alt="" />
          </div>
        ))}
      </div>
      <div className={`${text1Container}`}>
        <ReactMarkdown children={text_col_1} />
      </div>
      <div className={`${text2Container}`}>
        <ReactMarkdown children={text_col_2} />
      </div>
      {/* Close the Credits on the Touchwall by tapping anywhere */}
      {touchwall && (
        <>
          <div className={`${closeText}`}>
            <h4>Tap anywhere to continue</h4>
          </div>
          <div
            role="button"
            tabIndex="-1"
            className={`${closeOverlay}`}
            onClick={() => {
              navigate(-1);
            }}
            onKeyDown={(e) =>
              (e.key === 'Enter' || e.key === ' ') && navigate(-1)
            }
          ></div>
        </>
      )}
    </div>
  );
};

export default memo(Credits);
