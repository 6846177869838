import { atom } from 'recoil';

export const clickedScreenState = atom({
  key: 'clickedScreenState',
  default: 'centerScreen',
});

export const newsletterOpenState = atom({
  key: 'newsletterOpenState',
  default: false,
});

export const hotspotsReadyState = atom({
  key: 'hotspotsReadyState',
  default: false,
});
