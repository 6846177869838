import { graphql, useStaticQuery } from 'gatsby';
import gsap from 'gsap';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { clickedScreenState, hotspotsReadyState } from '../recoil/Atoms';
import HotspotOverlayImage from './HotspotOverlayImage';
import HotspotOverlayMarker from './HotspotOverlayMarker';
import ZoomInIcon from './icons/ZoomInIcon';
import ZoomOutIcon from './icons/ZoomOutIcon';
import ZoomResetIcon from './icons/ZoomResetIcon';
import {
  controlsContainer,
  loadingSpinner,
  muralFilter,
  viewerContainer,
  zoomButton,
} from './OSDViewer.module.scss';

const OSDViewer = ({
  osdId,
  pageId,
  pageName,
  xmlData,
  viewer,
  setViewer,
  hotspots,
  activeHotspot,
  setActiveHotspot,
  activeCategory,
  // setClickedScreen,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          pathPrefix
        }
      }
    `
  );

  const [clickedScreen, setClickedScreen] = useRecoilState(clickedScreenState);
  const [hotspotsReady, setHotspotsReady] = useRecoilState(hotspotsReadyState);

  // Gatsby typically has trouble with OpenSeadragon constructors, creating a ref seems to get around that.
  // Constructors can be used with this ref like: new openSeadragonRef.current.default.Rect()
  const openSeadragonRef = useRef(null);

  // Ref for animating the openSeadragon div
  const openSeadragonDivRef = useRef();

  const loadedTlRef = useRef();
  const spinnerTlRef = useRef();
  const spinnerRef = useRef(null);

  // disableHotspotBtns needs both a state and ref so that state isn't stale in handlers
  const [disableHotspotBtns, setDisableHotspotBtns] = useState(false);
  const disableHotspotBtnsRef = useRef(false);
  useEffect(() => {
    disableHotspotBtnsRef.current = disableHotspotBtns;
  }, [disableHotspotBtns]);

  // Set up viewer handlers for loading timelines
  useEffect(() => {
    loadedTlRef.current = gsap.timeline({ paused: true });
    spinnerTlRef.current = gsap.timeline({ paused: true });
    // don't do any of this on the homescreen, it's not interactable and needs no load spinner
    if (pageId !== 'home') {
      // hide osd container to start, hiding hotspot load
      gsap.set(openSeadragonDivRef.current, { opacity: 0 });

      loadedTlRef.current.to(openSeadragonDivRef.current, {
        opacity: 1,
        duration: 0.5,
      });
      spinnerTlRef.current.to(spinnerRef.current, {
        opacity: 0,
        duration: 1,
        delay: 0.5,
        ease: 'power1.out',
      });
      // wait until viewer variable initialized
      if (viewer) {
        // check if it's already open
        if (viewer.isOpen()) {
          loadedTlRef.current.play();
          spinnerTlRef.current.play();
        }
        // wait until osd is fully initialized
        viewer.addOnceHandler('open', () => {
          // console.log('open');
          loadedTlRef.current.play();
          // this event must be subscribed after open, otherwise tile object null
          viewer.world
            .getItemAt(0)
            .addOnceHandler('fully-loaded-change', () => {
              // console.log('loaded');
              spinnerTlRef.current.play();
            });
        });

        viewer.addHandler('canvas-pinch', (e) => {
          // zoom out detection to prevent zoom out jitter
          let zoom = viewer.viewport.getZoom(true);
          let distance = e.distance - e.lastDistance;
          if (zoom < viewer.minZoomLevel && distance < 0) {
            viewer.gestureSettingsTouch.pinchToZoom = false;
          } else {
            viewer.gestureSettingsTouch.pinchToZoom = true;
          }
        });

        viewer.addHandler('home', () => {
          if (pageId === 'map') {
            // recalculate vertical zoom position before going home
            // (also covers if window resized)
            var verticalFitZoom =
              viewer.source.aspectRatio /
              (viewer.viewport.containerSize.x /
                viewer.viewport.containerSize.y);
            viewer.viewport.defaultZoomLevel = verticalFitZoom;
          }
        });
      }
    }

    return () => {
      loadedTlRef.current.kill();
      spinnerTlRef.current.kill();
    };
  }, [pageId, viewer]);

  // Set which screen was clicked
  const getScreen = (e) => {
    let clientX = e.originalEvent.clientX;
    let innerWidth = window.innerWidth;
    if (clientX < innerWidth / 3) {
      setClickedScreen('leftScreen');
    } else if (clientX >= innerWidth / 3 && clientX < (innerWidth / 3) * 2) {
      setClickedScreen('centerScreen');
    } else if (clientX >= (innerWidth / 3) * 2) {
      setClickedScreen('rightScreen');
    }
  };

  // Show the active hotspot and which screen it should be on
  const handleHotspotClick = (id, e) => {
    getScreen(e);
    setActiveHotspot(id);
  };

  // OpenSeadragon Options
  const InitOpenSeadragon = (OpenSeadragon, pageId) => {
    // Set different options for different pages
    const pageOptions = {
      home: {
        defaultZoomLevel: 2, // TODO made the canvas bigger on the homepage but there might be a better solution https://stackoverflow.com/questions/63037117/using-openseadragon-how-can-set-it-to-load-the-image-at-a-specific-set-of-coord
        springStiffness: 3,
        showNavigator: false,
        showZoomControl: false, // the custom buttons must also be hidden in jsx
        showHomeControl: false,
      },
      map: {
        defaultZoomLevel: 3.7, // this number doesn't matter anymore, it's being overwritten in the home event
        springStiffness: 5,
        showNavigator: true,
        showZoomControl: true,
        showHomeControl: true,
      },
      mural: {
        defaultZoomLevel: 1,
        springStiffness: 5,
        showNavigator: true,
        showZoomControl: true,
        showHomeControl: true,
      },
    };

    setViewer(
      OpenSeadragon.default({
        id: 'openSeadragon',
        tileSources: xmlData,
        minPixelRatio: 0.75,
        minZoomLevel: 1,
        defaultZoomLevel: pageOptions[pageId].defaultZoomLevel,
        blendTime: 0.3,
        animationTime: 1.5,
        //homeFillsViewer: true, // I don't think this matters???
        maxZoomPixelRatio: 1,
        visibilityRatio: 1,
        constrainDuringPan: true,
        springStiffness: pageOptions[pageId].springStiffness,
        gestureSettingsMouse: {
          clickToZoom: false,
          dblClickToZoom: false,
          pinchToZoom: true,
          flickEnabled: true,
        },
        gestureSettingsTouch: {
          clickToZoom: false,
          dblClickToZoom: false,
          pinchToZoom: true,
          flickEnabled: true,
        },
        zoomPerScroll: 1.1,
        // preserveImageSizeOnResize: true,
        showNavigator: pageOptions[pageId].showNavigator,
        navigatorId: 'navigatorContainer',
        showFullPageControl: false,
        showZoomControl: pageOptions[pageId].showZoomControl,
        showHomeControl: pageOptions[pageId].showHomeControl,
        zoomInButton: 'zoomIn',
        zoomOutButton: 'zoomOut',
        homeButton: 'zoomReset',
        autoHideControls: false,
        navigatorAutoResize: true,
        imageLoaderLimit: 70,
        overlays: hotspots?.map((hotspot) =>
          setupHotspots(OpenSeadragon, hotspot)
        ),
      })
    );
  };

  // Initialize OpenSeadragon
  useEffect(() => {
    viewer && viewer.destroy();
    // site.pathPrefix was making urls like _dev/_dev
    // if (site.pathPrefix) {
    //   xmlData.Image.Url = `${site.pathPrefix}${xmlData.Image.Url}`;
    // }
    import('openseadragon').then((OpenSeadragon) => {
      // Add OpenSeadragon to a ref to make its constructors available
      openSeadragonRef.current = OpenSeadragon;
      // Init OpenSeadragon
      InitOpenSeadragon(OpenSeadragon, pageId);
    });
    return () => {
      viewer && viewer.destroy();
      setViewer(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Generate Hotspot from information found within each hotspotData value
  const setupHotspots = (OpenSeadragon, hotspot) => {
    const canvasWidth = xmlData.Image.Size.Width;
    new OpenSeadragon.default.MouseTracker({
      element: `overlay-${hotspot.hotspot_id}`,
      preProcessEventHandler: (e) => {
        switch (e.eventType) {
          // Allow dragging the canvas on top of a hotspot without triggering a click event
          // Not quite sure why it works, and honestly the logic seems backwards, but whatever
          case 'pointermove':
            e.preventGesture = disableHotspotBtnsRef.current;
            break;
          default:
            break;
        }
      },
      clickHandler: (e) => {
        // This condition stops handleHotspotClick() from firing at the end of a drag event
        !disableHotspotBtnsRef.current &&
          handleHotspotClick(hotspot.hotspot_id, e);
      },
      keyDownHandler: (e) =>
        // Weird stuff happens if you drag and tab to hotspots at the same time, but this condition prevents some issues
        !disableHotspotBtnsRef.current &&
        (e.originalEvent.key === 'Enter' || e.originalEvent.key === ' ') &&
        handleHotspotClick(hotspot.hotspot_id, e),
      dragHandler: () => {
        // setDisabledHotspotBtns to true, but only once... dragHandler fires a lot.
        !disableHotspotBtnsRef.current && setDisableHotspotBtns(true);
      },
      dragEndHandler: (e) => {
        setDisableHotspotBtns(false);
        // FIXME trying to make the nav elements move makes hotspots open wtf... probably the state update is retriggering something
        // Move the bottom nav to the screen the drag event ended on
        // getScreen(e);
      },
    });

    // Send the new data back to OSD to use for hotspots
    if (hotspot.osd.osd_id === 'mural')
      // Mural. Hotspot are an area; images with a width and height.
      return {
        id: `overlay-${hotspot.hotspot_id}`,
        // Convert pixel coordinates to percentages
        x: hotspot.x_position / canvasWidth,
        y: hotspot.y_position / canvasWidth,
        width: hotspot.hotspot_image?.width / canvasWidth,
        height: hotspot.hotspot_image?.height / canvasWidth,
        placement: 'TOP_LEFT',
      };
    else {
      // Map. Hotspots are a single point; an icon that does not need width and height.
      return {
        id: `overlay-${hotspot.hotspot_id}`,
        x: hotspot.x_position / canvasWidth,
        y: hotspot.y_position / canvasWidth,
        placement: 'CENTER',
      };
    }
  };

  // Set the global hotspotsReady state to true after OSD has placed them
  useEffect(() => {
    // OSD viewer isn't open yet, so viewer.isOpen() === false. This
    viewer?.addOnceHandler('open', () => {
      setHotspotsReady(true);
    });

    // Sometimes the viewer is already open, but we still need to update hotspotsReady
    viewer?.isOpen() && setHotspotsReady(true);

    // eslint-disable-next-line
  }, [viewer]);

  // Zoom to a hotspot when it's active
  useEffect(() => {
    // This condition is weird, because viewer.getOverlayById(activeHotspot) would return null and throw an error
    // even though all the hotspots were in viewer.overlays
    // Probably the elements aren't immediately in the dom
    // hotspotsReady === true also doesn't work as a condition here, we have to check for the activeHotspot id
    if (viewer?.getOverlayById(`overlay-${activeHotspot}`) && activeHotspot) {
      const rect = viewer.getOverlayById(`overlay-${activeHotspot}`).bounds;
      //TODO: does this still need to be debounced now?
      const recalculateRect = (rect, scale) => {
        // Resize the rectangle to add padding around the area we're zooming to
        let rectScaled = rect.times(scale);
        // Set an area for hotspots that only have X, Y points and no width or height
        if (rectScaled.width === 0 && rectScaled.height === 0) {
          rectScaled.width = 480 / xmlData.Image.Size.Width;
          rectScaled.height = 480 / xmlData.Image.Size.Width;
        }
        // The new rectangle will have scaled from the top left, so it needs to be translated back to its original center position
        let rectCenter = rect.getCenter();
        let scaledCenter = rectScaled.getCenter();
        let vector = rectCenter.minus(scaledCenter);
        return rectScaled.translate(vector);
      };

      // Temporarily slow down the animation time when a hotspot is clicked.
      // After fitBoundsWithConstraints() runs, the original fast animationTime value is restored for panning and zooming.
      // Based on https://stackoverflow.com/questions/25020629/can-i-slow-down-openseadragon-pan-zoom-animations
      const viewport = viewer.viewport;
      const initialAnimationTime = viewport.animationTime; // Set above, in OSD's default options
      const newAnimationTime = pageId === 'home' ? 10 : 4;

      // Set new animationTime
      viewport.centerSpringX.animationTime = newAnimationTime;
      viewport.centerSpringY.animationTime = newAnimationTime;
      viewport.zoomSpring.animationTime = newAnimationTime;

      // Animate to active hotspot
      viewport.fitBoundsWithConstraints(recalculateRect(rect, 1.4));

      // Revert to initial animationTime
      viewport.centerSpringX.animationTime = initialAnimationTime;
      viewport.centerSpringY.animationTime = initialAnimationTime;
      viewport.zoomSpring.animationTime = initialAnimationTime;
    }
    // eslint-disable-next-line
  }, [viewer, activeHotspot]);

  return (
    <div className={`${viewerContainer}`}>
      <div
        id="openSeadragon"
        style={{ width: '100%', height: '100%' }}
        // The mural image is dimmed slightly (on the mural page, not on the homepage), and the hotspots have a blend mode to make those spots a little brighter again.
        className={`${
          pageId === 'mural' && activeCategory !== '' ? muralFilter : ''
        }`}
        ref={openSeadragonDivRef}
      >
        {/* OpenSeadragon makes a bunch of hotspots with ids, then it replaces them with this component with a matching id */}
        {hotspots?.map((hotspot, index) =>
          osdId === 'mural' ? (
            // Mural
            <HotspotOverlayImage
              key={index}
              hotspot={hotspot}
              activeHotspot={activeHotspot}
              activeCategory={activeCategory}
              pageId={pageId}
            />
          ) : (
            // Map
            <HotspotOverlayMarker
              key={index}
              hotspot={hotspot}
              activeHotspot={activeHotspot}
              activeCategory={activeCategory}
              index={index}
            />
          )
        )}
      </div>
      {pageId !== 'home' && (
        // Zoom buttons for the web interface
        <Fragment>
          <div className={`${controlsContainer}`}>
            <button
              id="zoomReset"
              className={`${zoomButton}`}
              aria-label="Reset Zoom"
            >
              <ZoomResetIcon />
            </button>
            <button
              id="zoomIn"
              className={`${zoomButton}`}
              aria-label="Zoom In"
            >
              <ZoomInIcon />
            </button>

            <button
              id="zoomOut"
              className={`${zoomButton}`}
              aria-label="Zoom Out"
            >
              <ZoomOutIcon />
            </button>
          </div>
          <div className={`${loadingSpinner}`} ref={spinnerRef} />
        </Fragment>
      )}
    </div>
  );
};
export default OSDViewer;
