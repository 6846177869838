import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useRecoilState } from 'recoil';
import { newsletterOpenState } from '../recoil/Atoms';
import CloseIcon from './icons/CloseIcon';
import {
  closeButton,
  mediaContainer,
  popupContainer,
  textContainer,
  titleWrapper,
} from './NewsletterPopup.module.scss';
const NewsletterPopup = () => {
  const [newsletterOpen, setNewsletterOpen] =
    useRecoilState(newsletterOpenState);

  return (
    // ID is for gsap
    <div id="newsletter-popup" className={`hotspotPopup ${popupContainer}`}>
      <button
        type="button"
        aria-label="Close Popup"
        onClick={() => setNewsletterOpen(false)}
        className={`${closeButton}`}
      >
        <CloseIcon />
      </button>
      <div className={`${mediaContainer}`}>
        <StaticImage
          src="../images/camden-newsletter-qr-code.png"
          alt="Camden History"
        />
      </div>
      <div className={`${textContainer}`}>
        <h2 className={`${titleWrapper}`}>E-Newsletter</h2>
        <p>
          Use your phone's camera to scan the QR Code to visit the Camden County
          History Alliance E-Newsletter webpage. Or type cchsnj.org/e-newsletter
          into your web browser.
        </p>
        <p className="mb24">
          There you can subscribe to the monthly E-Newsletter, as well as view
          previous months' newsletters. The E-Newsletter contains contains a
          list of upcoming events at historic sites in Camden County and flyers
          about programs sponsored by history organizations throughout the
          county.
        </p>
      </div>
    </div>
  );
};
export default NewsletterPopup;
