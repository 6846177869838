import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import {
  backContainer,
  headerContainer,
  logo,
  logoWrapper,
} from './Header.module.scss';

const Header = () => {
  return (
    <header className={`${headerContainer}`}>
      <a
        href="https://www.ci.camden.nj.us/about-us/"
        className={`${backContainer}`}
      >
        <StaticImage
          src="../images/city-of-camden-logo-circle.jpg"
          alt="City of Camden Logo"
          className={`${logoWrapper}`}
          imgClassName={`${logo}`}
          objectFit="contain"
          objectPosition="0 50%"
        />
        <span className={`ml8`}>Back to City of Camden</span>
      </a>
    </header>
  );
};

export default Header;
