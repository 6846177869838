import React from 'react';

const ZoomOutIcon = () => {
  return (
    <svg viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8" y="18" width="23" height="3" rx="0.5" fill="#F0F0F0" />
    </svg>
  );
};
export default ZoomOutIcon;
