import React from 'react';

const CloseIcon = () => {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="33.3984"
        y="11.4791"
        width="3"
        height="31"
        rx="0.5"
        transform="rotate(45 33.3984 11.4791)"
        fill="#F0F0F0"
      />
      <rect
        x="13.5996"
        y="11.4791"
        width="31"
        height="3"
        rx="0.5"
        transform="rotate(45 13.5996 11.4791)"
        fill="#F0F0F0"
      />
    </svg>
  );
};
export default CloseIcon;
