import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

const Seo = ({ image, pageTitle }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteURL
          }
        }
      }
    `
  );

  const { title, description, siteURL } = site.siteMetadata;

  const fullTitle = pageTitle ? pageTitle + ' | ' + title : title;

  const imgURL =
    image && image.localFile
      ? `${site.siteMetadata.siteURL}${image.localFile.childImageSharp.seo.images.fallback.src}`
      : null;
  const imgURLUnsecure = imgURL ? imgURL.replace('https', 'http') : null;

  return (
    <Helmet>
      <html lang="en" />
      <title itemProp="name" lang="en">
        {fullTitle}
      </title>
      <meta name="description" content={description} />
      {/* Don't worry about viewport and charset, those are handled by gatsby */}

      {/* Social Media Tags */}
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteURL} />
      <meta property="og:description" content={description} />

      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />

      {image && imgURL ? (
        <meta name="twitter:card" content="summary_large_image" />
      ) : (
        <meta name="twitter:card" content="summary" />
      )}

      {image && imgURL && <meta property="og:image" content={imgURLUnsecure} />}
      {image && imgURL && (
        <meta property="og:image:secure_url" content={imgURL} />
      )}
      {image && <meta property="og:image:width" content={image.width} />}
      {image && <meta property="og:image:height" content={image.height} />}
    </Helmet>
  );
};

export default Seo;
