export const categoryBgColors = {
  NONE: 'bgColorFlint',
  // Mural
  EVENT: 'bgColorCopper',
  PERSON: 'bgColorBlue',
  PLACE: 'bgColorGreen',
  ALL_MURAL: 'bgColorFlint',
  // Map
  ORGANIZATION: 'bgColorBlue',
  SITE: 'bgColorCopper',
  WAR: 'bgColorGreen',
  ALL_MAP: 'bgColorFlint',
};

export const categorySvgColors = {
  ORGANIZATION: 'svgColorBlue',
  SITE: 'svgColorCopper',
  WAR: 'svgColorGreen',
};
