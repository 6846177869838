import React from 'react';
import { categorySvgColors } from '../vars/categoryColors';
import {
  hotspotActive,
  hotspotButton,
  hotspotNumber,
} from './HotspotOverlayMarker.module.scss';

const HotspotOverlayMarker = ({
  hotspot,
  activeHotspot,
  index,
  activeCategory,
}) => {
  const { hotspot_id } = hotspot;
  const isActive = activeHotspot === hotspot_id;
  return (
    <div
      role="button"
      // tabIndex={activeHotspot === '' ? 0 : -1} // this makes sense for popups, but not the sidebar view
      tabIndex="0"
      // id is required for OSD to replace its default hotspot with this element
      id={`overlay-${hotspot_id}`}
      className={`${hotspotButton} ${categorySvgColors[hotspot.category]} ${
        isActive ? hotspotActive : ''
      }
      ${
        activeCategory === hotspot.category || activeCategory === 'ALL_MAP'
          ? ''
          : 'hide'
      }
      `}
      aria-label={hotspot.title}
      // event handlers probably aren't needed because they're setup with OpenSeadragon.default.MouseTracker() in OSDViewer.js
      // onClick={clickHandler}
      // onKeyDown={clickHandler}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path
          d="M46.48,23.12l-7.15-3.91V14.42a5.74,5.74,0,0,0-5.75-5.75H28.79L24.88,1.52a1,1,0,0,0-1.76,0L19.21,8.67H14.42a5.74,5.74,0,0,0-5.75,5.75v4.79L1.52,23.12a1,1,0,0,0,0,1.76l7.15,3.91v4.79a5.74,5.74,0,0,0,5.75,5.75h4.79l3.91,7.15a1,1,0,0,0,1.76,0l3.91-7.15h4.79a5.74,5.74,0,0,0,5.75-5.75V28.79l7.15-3.91A1,1,0,0,0,46.48,23.12Z"
          stroke="#f0f0f0"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <text className={`${hotspotNumber}`} x="50%" y="50%" dy="12.5%">
          {index + 1}
        </text>
      </svg>
    </div>
  );
};
export default HotspotOverlayMarker;
