// extracted by mini-css-extract-plugin
export var link = "Credits-module--link--1FjWd";
export var h1 = "Credits-module--h1--jwdop";
export var h2 = "Credits-module--h2--3y1jz";
export var h3 = "Credits-module--h3--3_deR";
export var h4 = "Credits-module--h4--3Qg6p";
export var h5 = "Credits-module--h5--3Pyvh";
export var h6 = "Credits-module--h6--31Fj8";
export var p = "Credits-module--p--18imZ";
export var credit = "Credits-module--credit--1tu1k";
export var figcaption = "Credits-module--figcaption--Rjj5g";
export var footer = "Credits-module--footer--3isw5";
export var buttonTextAction = "Credits-module--buttonTextAction--2w3-Z";
export var button = "Credits-module--button--Mm94c";
export var buttonTextFooter = "Credits-module--buttonTextFooter--3ULLg";
export var buttonTextFilter = "Credits-module--buttonTextFilter--OAnWF";
export var listItemTouchwall = "Credits-module--listItemTouchwall--R8lJl";
export var listNumberTouchwall = "Credits-module--listNumberTouchwall--37oXA";
export var popupNumber = "Credits-module--popupNumber--26qZN";
export var creditsPage = "Credits-module--creditsPage--1S428";
export var textColorWhite = "Credits-module--textColorWhite--1q0Vp";
export var textColorBlack = "Credits-module--textColorBlack--39L4s";
export var strong = "Credits-module--strong--31fth";
export var noDecoration = "Credits-module--noDecoration--3WflL";
export var gatsbyFocusWrapper = "Credits-module--gatsby-focus-wrapper--2D3pG";
export var sixteenNineBox = "Credits-module--sixteenNineBox--KaBd8";
export var fourThreeBox = "Credits-module--fourThreeBox--17sQ4";
export var sixteenNineBoxInner = "Credits-module--sixteenNineBoxInner--1PZH6";
export var btnDisabled = "Credits-module--btnDisabled--3rqyP";
export var backBtn = "Credits-module--backBtn--1MCla";
export var toggleButton = "Credits-module--toggleButton--2ueZh";
export var toggleButtonActive = "Credits-module--toggleButtonActive--3z6xm";
export var inlineIcon = "Credits-module--inlineIcon--1mB8H";
export var creditsContainer = "Credits-module--creditsContainer--2ztWY";
export var touchwallGrid = "Credits-module--touchwallGrid--1QUrr";
export var header = "Credits-module--header--2rrEm";
export var logosContainer = "Credits-module--logosContainer--nld_z";
export var text1Container = "Credits-module--text1Container--2XJAG";
export var text2Container = "Credits-module--text2Container--3uKH-";
export var logoWrapper = "Credits-module--logoWrapper--1qvMo";
export var closeText = "Credits-module--closeText--3jMa2";
export var closeOverlay = "Credits-module--closeOverlay--2sd1b";