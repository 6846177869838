import { useLocation } from '@reach/router';
import gsap from 'gsap';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Credits from './components/Credits';
import Footer from './components/Footer';
import Header from './components/Header';
import NewsletterPopup from './components/NewsletterPopup';
import Seo from './components/Seo';
import TimeoutOverlay from './components/TimeoutOverlay';
import {
  credits,
  footer,
  header,
  main,
  newsletter,
  pageGrid,
} from './Layout.module.scss';
import './Layout.scss';
import { clickedScreenState, newsletterOpenState } from './recoil/Atoms';

const Layout = ({ children, location }) => {
  const touchwall = process.env.GATSBY_CAMDEN_OFFLINE === 'true';
  const { pathname } = useLocation();

  // Move the footer to the center whenever the page changes
  const [clickedScreen, setClickedScreen] = useRecoilState(clickedScreenState);
  const newsletterOpen = useRecoilValue(newsletterOpenState);

  const layoutRef = useRef();
  const newsletterRef = useRef();
  const newsletterOpenTlRef = useRef();
  const selectEl = gsap.utils.selector(layoutRef);
  useEffect(() => {
    setClickedScreen('centerScreen');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    newsletterOpenTlRef.current = gsap.timeline({ paused: true });
    // TODO I think this would be cleaner with .reverse()
    // Show the popup
    if (newsletterOpen) {
      newsletterOpenTlRef.current
        .addLabel('start')
        .to(
          newsletterRef.current,
          {
            pointerEvents: 'all',
            background: 'rgba(0, 0, 0, 0.3)',
            duration: 0.3,
          },
          'start'
        )
        .to(
          selectEl(`#newsletter-popup`),
          { autoAlpha: 1, duration: 0.3 },
          'start+=0.5'
        );
      newsletterOpenTlRef.current.play();
    } else {
      // Hide the popup
      newsletterOpenTlRef.current
        .addLabel('start')
        .to(
          selectEl(`#newsletter-popup`),
          { autoAlpha: 0, duration: 0.3 },
          'start'
        )
        .to(
          newsletterRef.current,
          {
            pointerEvents: 'none',
            background: 'rgba(0, 0, 0, 0)',
            duration: 0.3,
          },
          'start+=0.5'
        );
      newsletterOpenTlRef.current.play();
    }
    return () => {
      // Add cleanup with .context() and .revert()?
    };
    // eslint-disable-next-line
  }, [newsletterOpen]);

  const creditsRef = useRef();
  const creditsOpenTlRef = useRef();

  useEffect(() => {
    let creditsOpen = location.hash === '#credits';
    creditsOpenTlRef.current = gsap.timeline({ paused: true });
    // TODO I think this would be cleaner with .reverse()
    // Show the credits
    if (creditsOpen) {
      creditsOpenTlRef.current
        .addLabel('start')
        .set(creditsRef.current, { zIndex: 50 }, 'start') // Fixes bug where card list wouldn't scroll in Chrome device emulation
        .to(
          selectEl(`#credits`),
          { autoAlpha: 1, pointerEvents: 'all', duration: 0.3 },
          'start'
        );
      creditsOpenTlRef.current.play();
    } else {
      // Hide the credits
      creditsOpenTlRef.current
        .addLabel('start')
        .to(
          selectEl(`#credits`),
          { autoAlpha: 0, pointerEvents: 'none', duration: 0.3 },
          'start'
        )
        .set(creditsRef.current, { zIndex: -10 }, 'start+=0.3'); // Fixes bug where card list wouldn't scroll in Chrome device emulation
      creditsOpenTlRef.current.play();
    }
    return () => {
      // Add cleanup with .context() and .revert()?
    };
    // eslint-disable-next-line
  }, [location]);

  return (
    <>
      <Seo />
      {pathname !== '/' && touchwall && <TimeoutOverlay />}
      <div className={`${pageGrid}`} ref={layoutRef}>
        <div className={`${header}`}>
          <Header />
        </div>

        <main className={`${main}`}>{children}</main>

        <div
          className={`${newsletter}`}
          style={{
            justifyItems: {
              leftScreen: 'start',
              centerScreen: 'center',
              rightScreen: 'end',
            }[clickedScreen],
          }}
          ref={newsletterRef}
        >
          <NewsletterPopup />
        </div>

        <div className={`${credits}`} ref={creditsRef}>
          <Credits />
        </div>

        <div className={`${footer}`}>
          <Footer />
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
