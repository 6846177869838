// extracted by mini-css-extract-plugin
export var link = "Footer-module--link--1KQCJ";
export var h1 = "Footer-module--h1--1OuDL";
export var h2 = "Footer-module--h2--v866N";
export var h3 = "Footer-module--h3--1vHwk";
export var h4 = "Footer-module--h4--1Myql";
export var h5 = "Footer-module--h5--13pdc";
export var h6 = "Footer-module--h6--3zWRv";
export var p = "Footer-module--p--LL1oe";
export var credit = "Footer-module--credit--CTCcu";
export var figcaption = "Footer-module--figcaption--3jlVa";
export var footer = "Footer-module--footer--31LQy";
export var buttonTextAction = "Footer-module--buttonTextAction--3XH44";
export var button = "Footer-module--button--1d-Zv";
export var buttonTextFooter = "Footer-module--buttonTextFooter--3rUIA";
export var buttonTextFilter = "Footer-module--buttonTextFilter--7ou84";
export var listItemTouchwall = "Footer-module--listItemTouchwall--2x8Cd";
export var listNumberTouchwall = "Footer-module--listNumberTouchwall--vbztj";
export var popupNumber = "Footer-module--popupNumber--21M4z";
export var creditsPage = "Footer-module--creditsPage--19uQE";
export var textColorWhite = "Footer-module--textColorWhite--1e4mN";
export var textColorBlack = "Footer-module--textColorBlack--1TEeM";
export var strong = "Footer-module--strong--2MPSe";
export var noDecoration = "Footer-module--noDecoration--2JN78";
export var gatsbyFocusWrapper = "Footer-module--gatsby-focus-wrapper--7mLRW";
export var sixteenNineBox = "Footer-module--sixteenNineBox--DCpNo";
export var fourThreeBox = "Footer-module--fourThreeBox--3WkMJ";
export var sixteenNineBoxInner = "Footer-module--sixteenNineBoxInner--3C7Qk";
export var btnDisabled = "Footer-module--btnDisabled--3tZHx";
export var homeBtn = "Footer-module--homeBtn--3kPTQ";
export var toggleButton = "Footer-module--toggleButton--28CMV";
export var toggleButtonActive = "Footer-module--toggleButtonActive--1lVW7";
export var inlineIcon = "Footer-module--inlineIcon--2DWLa";
export var footerContainer = "Footer-module--footerContainer--3IMQA";
export var footerNav = "Footer-module--footerNav--6cadu";
export var footerHome = "Footer-module--footerHome--NLqfJ";
export var menu = "Footer-module--menu--nQw4P";