import React from 'react';
import SvgInliner from './SvgInliner';

import { hotspotBtn, noOutline } from './HotspotOverlayImage.module.scss';

const HotspotOverlayImage = ({
  hotspot,
  activeHotspot,
  activeCategory,
  pageId,
  disableHotspotBtns,
}) => {
  return (
    <div
      role="button"
      // tabIndex={activeHotspot === '' ? 0 : -1} // this makes sense for popups, but not the sidebar view
      tabIndex={pageId === 'home' ? '-1' : 0} // hotspots should not be tabbable on the home loop
      // id is required for OSD to replace its default hotspot with this element
      id={`overlay-${hotspot.hotspot_id}`}
      className={`${hotspotBtn} ${
        activeCategory === hotspot.category || activeCategory === 'ALL_MURAL'
          ? ''
          : 'hide'
      } ${pageId === 'home' ? noOutline : ''}
      `}
      aria-label={hotspot.title}
      // event handlers probably aren't needed because they're setup with OpenSeadragon.default.MouseTracker() in OSDViewer.js
      // onClick={clickHandler}
      // onKeyDown={clickHandler}
    >
      <SvgInliner svgContent={hotspot.hotspot_image.localFile?.svgData} />
    </div>
  );
};
export default HotspotOverlayImage;
