import React from 'react';

const ZoomInIcon = () => {
  return (
    <svg viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="18" y="8" width="3" height="23" rx="0.5" fill="#F0F0F0" />
      <rect x="8" y="18" width="23" height="3" rx="0.5" fill="#F0F0F0" />
    </svg>
  );
};
export default ZoomInIcon;
