import { useLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { clickedScreenState, newsletterOpenState } from '../recoil/Atoms';
import {
  footerContainer,
  footerHome,
  footerNav,
  homeBtn,
  menu,
} from './Footer.module.scss';

const Footer = () => {
  const touchwall = process.env.GATSBY_CAMDEN_OFFLINE === 'true';
  const { pathname } = useLocation();

  const clickedScreen = useRecoilValue(clickedScreenState);
  const [newsletterOpen, setNewsletterOpen] =
    useRecoilState(newsletterOpenState);
  return (
    <div
      className={`footer ${footerContainer} ${
        {
          leftScreen: 'navLeft',
          centerScreen: 'navCenter',
          rightScreen: 'navRight',
        }[clickedScreen]
      }`}
    >
      <nav className={`${footerNav} ${pathname === '/' ? footerHome : ''}`}>
        {pathname !== '/' && (
          <Link to={`/`} className={`button ${homeBtn} buttonTextFooter`}>
            Camden History
          </Link>
        )}
        <ul className={`${menu}`} aria-label="Menu">
          {/* TODO Translation button is hidden for Beta, but should be reenabled in the future */}
          {/* <li>
            <Link to={`/`}>Español</Link>
          </li> */}
          <li>
            {touchwall ? (
              // Offline kiosk
              <span
                className={`link`}
                role="button"
                tabIndex="0"
                onClick={() => setNewsletterOpen(true)}
                onKeyDown={(e) =>
                  (e.key === 'Enter' || e.key === ' ') &&
                  setNewsletterOpen(true)
                }
              >
                E-Newsletter
              </span>
            ) : (
              // Online website
              <a
                href="https://cchsnj.org/e-newsletter/"
                target="_blank"
                rel="noopener noreferrer"
              >
                E-Newsletter
              </a>
            )}
          </li>
          <li>
            <span
              className={`link`}
              role="button"
              tabIndex="0"
              onClick={() => {
                navigate('#credits');
              }}
              onKeyDown={(e) =>
                (e.key === 'Enter' || e.key === ' ') && navigate('#credits')
              }
            >
              Credits
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Footer;
